import React from 'react';
import '../style/Main.css';
import MyBtn1 from "../components/UI/MyBtn1/MyBtn1";
import pic1 from '../img/pic1.png';
import pic1_2 from '../img/pic1_2.png';
import ImgByAnimation from "../components/UI/ImgByAnimation/ImgByAnimation";
import pic2 from '../img/pic2.png';
import pic3 from '../img/pic3.png';
import Request from "../components/Request/Request";
import valeria from '../img/pic4.png';
import ksenia from '../img/pic5.png';
import svetlana from '../img/pic6.png';
import karina from '../img/IMG_1868.JPG';
import anna from '../img/pic8.png';
import MyBtn2 from "../components/UI/MyBtn2/MyBtn2";
import backWhite1 from '../img/white-right-back.svg';
import backWhite2 from '../img/white-left-back.svg';
import backBraun1 from '../img/right-back.svg';
import backBraun2 from '../img/left-back.svg';
import { useTranslation } from "react-i18next";

const Main = () => {
	const { t, i18n } = useTranslation();

	return (
		<div className={'main'}>
			<section className="hero_section">
				<div className="container hero">
					<div className="hero__content">
						<h1 className="hero__name">{t('main.title')}<br/>{t('main.name')}</h1>
						<p className="hero__desc">{t('main.about1')}<br/>{t('main.about2')}</p>
						<MyBtn1 href={'#main_request'} className="hero__MyBtn" name={t('main.apply')}/>
					</div>
					<ImgByAnimation className="hero__img-block" img={pic1}/>
					<ImgByAnimation className="hero__img-block_2" img={pic1_2}/>
				</div>
			</section>
			<section className="about_section">
				<div className="container about">
					<h2 className="about__name">{t("main.about.title")}</h2>
					<p className="about__txt1" style={{position: "relative", zIndex: 5}}><b style={{ lineHeight: "170%"}}>{t("main.about.welcome")}</b><br/>
						{t("main.about.text1.line1")}<br/>{t("main.about.text1.line2")}<br/>{t("main.about.text1.line3")}
					</p>
					<div className="about__block_1">
						<ImgByAnimation className="about__block_1__img-block" img={pic2}/>
						<p className="about__block_1__txt">
							<b>{t("main.about.text2.line1")}</b> {t("main.about.text2.line2")}
						</p>
					</div>
					<div className="about__block_2">
						<div className="about__block_2__txt">
							{t("main.about.text3.title1") &&
								<h2 className="about__block_2__title">{t("main.about.text3.title1")}</h2>}
							{t("main.about.text3.line1")}
							{t("main.about.text3.line2") && <><br/>{t("main.about.text3.line2")}</>}
							{t("main.about.text3.line2") && <><br/><br/>{t("main.about.text3.line3")}</>}
							{t("main.about.text3.line2") && <><br/><br/>{t("main.about.text3.line4")}</>}
						</div>
						<ImgByAnimation className="about__block_2__img-block" img={pic3}/>
					</div>
					<p className="about__txt2">{t("main.about.text4.line1")}
						{t("main.about.text4.line2") && <><br/><br/>{t("main.about.text4.line2")}</>}
					</p>
				</div>
				<img src={backWhite1} className="about__back_1"/>
				<img src={backWhite2} className="about__back_2"/>
			</section>
			<section className="woman_section">
				<div className="container woman">
					<h2 className="woman__name">{t("main.slavic.title.line1")}&nbsp;
						<u>{t("main.slavic.title.line2")}</u>
						{t("main.slavic.title.line3") && <>&nbsp;{t("main.slavic.title.line3")}</>}
					</h2>
					<div className="woman__blocks">
						<div className="woman__left-block">
							<ImgByAnimation className={"woman__valeria"} img={valeria}/>
							<p className="woman__txt2">{t("main.slavic.text2")}</p>
							<ImgByAnimation className={"woman__svetlana"} img={svetlana}/>
							<p className="woman__txt4">{t("main.slavic.text4")}</p>
							<ImgByAnimation className={"woman__anna"} img={anna}/>
						</div>
						<div className="woman__right-block">
							<p className="woman__txt1">{t("main.slavic.text1")}</p>
							<ImgByAnimation className={"woman__ksenia"} img={ksenia}/>
							<p className="woman__txt3">{t("main.slavic.text3")}</p>
							<ImgByAnimation className={"woman__karina"} img={karina}/>
							<p className="woman__txt5">{t("main.slavic.text5.line1")}
								{t("main.slavic.text5.line2") && <><br/><br/>{t("main.slavic.text5.line2")}</>}
								<br/><br/>{t("main.slavic.text5.line3")} <b>{t("main.slavic.text5.lineBold")}</b></p>
						</div>
					</div>
					<MyBtn2 name={t("main.slavic.button")} href={'/womans'} className={'woman__btn'}/>
				</div>
				<img src={backBraun1} className="woman__back_1"/>
				<img src={backBraun2} className="woman__back_2"/>
				<img src={backBraun1} className="woman__back_3"/>
			</section>
			<Request id={'main_request'} background={'main_request'} img={backWhite2}/>
		</div>
	);
};

export default Main;