import React from 'react';
import '../style/Contacts.css';
import tel from '../img/tel.svg';
import mail from '../img/Icon_mail.svg';
import inst from '../img/inst.svg';
import fb from '../img/facebook.svg';
import wapp from '../img/wapp.svg';
import back from '../img/left-back.svg';
import { useTranslation } from "react-i18next";

const Contacts = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={'contacts_section'}>
      <div className="container contacts">
        <h2 className="contacts__name">{t('contacts.title')}</h2>
        <div className="contacts__content">
          <div className="contacts__tel">
            <img src={tel} alt="tel" className="contacts__tel__img"/>
            <a href="tel:+33665169078" className="contacts__tel__a">+33 6 65 16 90 78</a>
          </div>
          <div className="contacts__mail">
            <img src={mail} alt="mail" className="contacts__mail__img"/>
            <a href="mailto:mail" className="contacts__mail__a">agence.lasf@gmail.com</a>
          </div>
        </div>
        <div className="contacts__icons">
          <a href="https://instagram.com/lasf.agence" className="contacts__icons__inst">
            <img src={inst} alt="instagram" className="contacts__icons__inst__img"/>
          </a>
          <a href="https://www.facebook.com/profile.php?id=100093200285349" className="contacts__icons__facebook">
            <img src={fb} alt="facebook" className="contacts__icons__facebook__img"/>
          </a>
          <a href="https://wa.me/+33665169078" className="contacts__icons__wapp">
            <img src={wapp} alt="what's app" className="contacts__icons__wapp__img"/>
          </a>
        </div>
        <img src={back} alt="" className="contacts__back"/>
      </div>
    </div>
  );
};

export default Contacts;