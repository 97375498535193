import REACT, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header/Header";
import './style/App.css';
import Main from "./pages/Main";
import Service from "./pages/Service";
import Womans from "./pages/Womans";
import History from "./pages/History";
import Contacts from "./pages/Contacts";
import Footer from "./components/Footer/Footer";
import './style_1000/App1000.css';
import './style_768/App768.css';
import './style_480/App480.css';
import './style_360/App360.css';
import Policy from "./pages/Policy";
import { useTranslation } from "react-i18next";
import useLocalStorage from "./hooks/useLocalStorage";

function App() {
	const [lang, setLang] = useLocalStorage("lang", "fr");
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (lang === 'fr') {
			i18n.changeLanguage('fr');
		} else {
			i18n.changeLanguage('en');
		}
	}, []);

	return (
		<BrowserRouter>
			<Header/>
			<Routes>
				<Route path={'/'} element={<Main/>}/>
				<Route path={'/service'} element={<Service/>}/>
				<Route path={'/womans'} element={<Womans/>}/>
				<Route path={'/history'} element={<History/>}/>
				<Route path={'/contacts'} element={<Contacts/>}/>
				<Route path={'/policy'} element={<Policy/>}/>
			</Routes>
			<Footer/>
		</BrowserRouter>
	);
}

export default App;
