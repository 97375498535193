export const formatDateToDDMMYYYY = (date, splitter) => {
	const d = new Date(date);
	const yyyy = d.getFullYear();
	let mm = d.getMonth() + 1; // Months start at 0!
	let dd = d.getDate();

	if (dd < 10) dd = '0' + dd;
	if (mm < 10) mm = '0' + mm;

	return dd + splitter + mm + splitter + yyyy;
}