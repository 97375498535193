import React from 'react';
import logo from '../../img/Heart.svg';
import tel from '../../img/tel.svg';
import fr from '../../img/flag_fr.svg'
import en from '../../img/flag_en.svg'
import './Header.css';
import useLocalStorage from "../../hooks/useLocalStorage";
import { useTranslation } from 'react-i18next';

const Header = () => {

	const tapToBurger = () => {
		const burger = document.querySelector('.header__burger');
		burger.classList.toggle('active-3');
		document.querySelector(".second__menu").classList.toggle('second__menu__active');
	}

	const { t, i18n } = useTranslation();

	const [_, setLang] = useLocalStorage("lang", "fr");

	const onENClick = () => {
		i18n.changeLanguage('en');
		setLang('en');
	}

	const onFRClick = () => {
		i18n.changeLanguage('fr');
		setLang('fr');
	}


	return (
		<div className={'header'}>
			<div className="container">
				<ul className="header_list">
					<li className="header_list_logo header_list_el">
						<a href="/" className="header_list_logo">
							<img src={logo} alt="logo" className="header_list_logo_img"/>
						</a>
					</li>
					<li className="header_list_el">
						<a href="/service" className="header_list_el_a">{t('header.service')}</a>
					</li>
					<li className="header_list_el">
						<a href="/womans" className="header_list_el_a">{t('header.ourClients')}</a>
					</li>
					<li className="header_list_el">
						<a href="/history" className="header_list_el_a">{t('header.ourHistory')}</a>
					</li>
					<li className="header_list_el">
						<a href="/contacts" className="header_list_el_a">{t('header.contacts')}</a>
					</li>
					<li className="header_list_el header_contacts">
						<img src={tel} alt="" className="header_list_el_tel-img"/>
						<a href="tel:+33665169078" className="header_list_el_a">+33 6 65 16 90 78</a>
					</li>
					<li className="header_list_el header_contacts">
						<button className="header__lang__button" onClick={onFRClick}>
							<img src={fr} alt="FR" className="header_list_el_tel-img"/>
						</button>
						<button className="header__lang__button" onClick={onENClick}>
							<img src={en} alt="EN" className="header_list_el_tel-img"/>
						</button>
					</li>
				</ul>
				<ul className="header__second">
					<li className="header__second__el">
						<a href="/" className="header__second__el header__second__logo">
							<img src={logo} alt="logo" className="header__second__logo_img"/>
						</a>
					</li>
					<li className="header__second__el__spans header__second__el">
						<a className="header__burger" onClick={tapToBurger}>
							<span></span>
							<span></span>
							<span></span>
						</a>
					</li>
				</ul>
				<div className="second__menu">
					<a href="/" className="second__menu__el second__menu__logo">
						<img src={logo} alt="logo" className="second__menu__logo_img"/>
					</a>
					<div className="second__menu__content">
						<a href="/service"
						   className="second__menu__content__el second__menu__content__left_el">{t('header.service')}</a>
						<a href="/womans"
						   className="second__menu__content__el second__menu__content__right_el">{t('header.ourClients')}</a>
						<a href="/history"
						   className="second__menu__content__el second__menu__content__left_el">{t('header.ourHistory')}</a>
						<a href="/contacts"
						   className="second__menu__content__el second__menu__content__right_el">{t('header.contacts')}</a>
						<div className="header__lang__buttons__mobile">
							<button className="header__lang__button" onClick={onFRClick}>
								<img src={fr} alt="FR" className="header_list_el_tel-img"/>
							</button>
							<button className="header__lang__button" onClick={onENClick}>
								<img src={en} alt="EN" className="header_list_el_tel-img"/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;