import React from 'react';
import Request from "../components/Request/Request";
import MyBtn1 from "../components/UI/MyBtn1/MyBtn1";
import '../style/Service.css';
import backBraun1 from '../img/right-back.svg';
import { useTranslation } from "react-i18next";

const Service = () => {
	const { t, i18n } = useTranslation();

	return (
		<section className={'service_section'}>
			<div className="container service">
				<h2 className="service__name">{t("service.title")}</h2>
				<p className="service__desc">{t("service.subtitle")}</p>
				<div className="service__services">
					<div className="service__services__first">
						<div>
							<h4 className="service__services__first_h">{t("service.card1.title")}</h4>
							<p className="service__services__first__p">
								{t("service.card1.text")}
								<br/><br/><b>( 550 &#8364; )</b>
							</p>
						</div>
						<MyBtn1 href={'#service_request'} className="service__MyBtn" name={t("service.apply")}/>
					</div>
					<div className="service__services__second">
						<div>
							<h4 className="service__services__second_h">{t("service.card2.title")}</h4>
							<p className="service__services__second__p">
								{t("service.card2.text")}
								<br/><br/><b>( 1550 &#8364; )</b>
							</p>
						</div>
						<MyBtn1 href={'#service_request'} className="service__MyBtn" name={t("service.apply")}/>
					</div>
				</div>
				<div className="service__txt">
					<h5 className="service__txt__h">{t("service.description.title")}</h5>
					<p className="service__txt__p">{t("service.description.text1")}
						{t("service.description.text2") && <>
							<br/><br/>{t("service.description.text2")}</>}
					</p>
				</div>
			</div>
			<Request id={'service_request'} background={'service_request'}/>
			<img src={backBraun1} className="service__back"/>
		</section>
	);
};

export default Service;