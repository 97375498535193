import React from 'react';
import './MyBtn1.css';

const MyBtn1 = (props) => {

  window.onload = function() {
    document.querySelector('.MyBtn').addEventListener('click', function (e) {
      e.preventDefault();

      let href = this.getAttribute('href').substring(1);

      const scrollTarget = document.getElementById(href);

      const topOffset = 100; // если не нужен отступ сверху
      const elementPosition = scrollTarget.getBoundingClientRect().top;
      const offsetPosition = elementPosition - topOffset;

      window.scrollBy({
        top: offsetPosition,
        behavior: 'smooth'
      });
    });
  };

  return (
    <div className={props.className}>
      <a className="MyBtn" href={props.href}>
        {props.name}
      </a>
    </div>
  );
};

export default MyBtn1;