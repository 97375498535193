import React from 'react';
import '../style/Policy.css';

const Policy = () => {
  return (
    <section>
      <p className="policy__txt">
        Politique de Confidentialité
        <br/><br/>
        lamoursansfrontieres.com / l'administration du site Web est tenue de préserver votre vie privée sur Internet. Nous accordons une grande attention à la sécurisation des données que vous nous avez fournies. Notre politique de confidentialité est basée sur le Règlement Général sur la Protection des Données (RGPD) de l'Union Européenne. Les finalités pour lesquelles nous collectons vos données personnelles sont: l'amélioration de notre service, la communication avec les visiteurs de ce site, la fourniture de services associés à la spécialisation du site, et pour les autres actions énumérées ci-dessous.
        <br/><br/>
        Stockage et traitement des données personnelles
        <br/><br/>
        Nous collectons et traitons vos données personnelles uniquement avec votre consentement. Avec votre permission, nous pouvons collecter et traiter les données suivantes: nom et prénom, numéro de téléphone, . La collecte et le traitement de vos informations personnelles sont effectués conformément aux lois de l'Union européenne et de la France.
        <br/><br/>
        Stockage, modification et suppression des données
        <br/><br/>
        L'utilisateur, qui a fourni lamoursansfrontieres.com / avec leurs données personnelles, a le droit de les modifier et de les supprimer, ainsi que le droit de rappeler l'accord sur le traitement des données. Le temps pendant lequel vos données personnelles seront stockées est: le temps d'utilisation des données personnelles nécessaires à l'activité principale du site Web. Une fois le traitement de vos données personnelles terminé, l'administration du site Web les supprimera définitivement. Pour accéder à vos données personnelles, vous pouvez contacter l'administration sur: agence.lasf@gmail.com. Nous ne pourrons transmettre vos données à un tiers qu'avec votre consentement. Si les données ont été transférées à un tiers, qui n'est pas associé à notre organisation, nous ne pouvons apporter aucune modification à ces données.
        <br/><br/>
        Traitement des données techniques de visite
        <br/><br/>
        Des enregistrements de votre adresse IP, de l'heure de la visite, des paramètres du navigateur, du système d'exploitation et d'autres informations techniques sont enregistrés dans la base de données lorsque vous visitez lamoursansfrontieres.com/. Ces données sont nécessaires à l'affichage correct du contenu du site Web. Il est impossible d'identifier la personne du visiteur à l'aide de ces données.
        <br/><br/>
        Informations personnelles des enfants
        <br/><br/>
        Si vous êtes le parent ou le tuteur légal d'un enfant mineur et que vous savez que l'enfant nous a fourni ses informations personnelles sans votre consentement, veuillez nous contacter à l'adresse suivante: agence.lasf@gmail.com. Il est interdit de saisir les données personnelles des utilisateurs mineurs sans l'accord des parents ou tuteurs légaux.
        <br/><br/>
        Traitement des cookies
        <br/><br/>
        Nous utilisons des fichiers cookies pour l'affichage correct du contenu du site Web et pour la commodité de la navigation lamoursansfrontieres.com/. Ce sont de petits fichiers stockés sur votre appareil. Ils aident le site Web à mémoriser des informations vous concernant, telles que la langue dans laquelle vous utilisez le site Web et les pages que vous avez déjà ouvertes. Ces informations vous seront utiles lors de la prochaine visite. Grâce aux fichiers cookies, la navigation sur le site devient beaucoup plus pratique. Vous pouvez en savoir plus sur ces fichiers ici. Vous pouvez configurer vous-même la réception et le blocage des cookies dans votre navigateur. L'impossibilité de recevoir des fichiers cookies peut limiter la fonctionnalité du site Web.
        <br/><br/>
        Traitement des données personnelles par d'autres services
        <br/><br/>
        Ce site Web utilise des services en ligne tiers, qui effectuent la collecte de données, indépendamment de nous. Ces services comprennent: Google Analytics , .
        <br/><br/>
        Les données collectées par ces services peuvent être fournies à d'autres services au sein de ces organisations. Ils peuvent utiliser les données pour la personnalisation publicitaire de leur propre réseau publicitaire. Vous pouvez en apprendre davantage sur les accords d'utilisation de ces organisations sur leurs sites Web. Vous pouvez également vous opposer à leur collecte de vos données personnelles. Par exemple, le module complémentaire de navigateur de désactivation de Google Analytics peut être trouvé ici . Nous ne transmettons aucune donnée personnelle à d'autres organisations ou services, qui ne sont pas répertoriés dans cette politique de confidentialité. Exceptionnellement, les données collectées peuvent être fournies sur demande légale des autorités de l'État, qui sont autorisées à demander de telles informations.
        <br/><br/>
        Liens vers d'autres sites Web
        <br/><br/>
        Notre site internet lamoursansfrontieres.com/ peut contenir des liens vers d'autres sites Web, qui ne sont pas sous notre contrôle. Nous ne sommes pas responsables du contenu de ces sites Web. Nous vous recommandons de vous familiariser avec la politique de confidentialité de chaque site Web que vous visitez, si une telle politique existe.
        <br/><br/>
        Modifications de la politique de confidentialité
        <br/><br/>
        De temps en temps, notre site Web lamoursansfrontieres.com / peut mettre à jour notre politique de confidentialité. Nous informons de toute modification de la politique de confidentialité, placée sur cette page Web. Nous surveillons toute évolution de la législation relative aux données personnelles dans l'Union européenne et en France. Si vous avez saisi l'une de vos données personnelles sur notre site Web, nous vous informerons des modifications de notre politique de confidentialité. Si vos données personnelles, et plus particulièrement vos coordonnées, ont été saisies de manière incorrecte, nous ne pourrons pas vous contacter.
        <br/><br/>
        Commentaires et clauses finales
        <br/><br/>
        Vous pouvez contacter l'administration de lamoursansfrontieres.com / concernant toute question relative à la politique de confidentialité sur: agence.lasf@gmail.com, ou en remplissant un formulaire de contact spécifié dans une section correspondante de ce site Web. Si vous n'êtes pas d'accord avec cette politique de confidentialité, vous ne pouvez pas utiliser les services de lamoursansfrontieres.com/. Dans ce cas, vous devez éviter de visiter notre site Web.
      </p>
    </section>
  );
};

export default Policy;