import React, { useCallback, useRef, useState } from 'react';
import './Woman.css';
import ImgByAnimation from "../ImgByAnimation/ImgByAnimation";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import prev from '../../../img/prev.svg';
import next from '../../../img/next.svg';

// import required modules
import { Pagination } from 'swiper/modules';
import { useTranslation } from "react-i18next";
import { formatDateToDDMMYYYY } from "../../../helpers/formatDate";
import { calculateAgeInYears } from "../../../helpers/calculateAgeInYears";

const Woman = (props) => {
	const { t, i18n } = useTranslation();

	const [isActive, setIsActive] = useState(false);
	const [clickedNav, setClickedNav] = useState(false);

	const onClick = (event) => {
		if (event.target.classList.contains("Woman__pic__nav")) {
			setIsActive(false);
		} else {
			setIsActive(!isActive);
		}
	}

	const sliderRef = useRef(null);

	const handlePrev = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slidePrev();
		setClickedNav(true);
	}, []);

	const handleNext = useCallback(() => {
		if (!sliderRef.current) return;
		sliderRef.current.swiper.slideNext();
		setClickedNav(true);
	}, []);

	const ages = calculateAgeInYears(props.birthdate);

	const formattedBirthday = formatDateToDDMMYYYY(new Date(props.birthdate), '.');

	return (
		<div className={props.className} onClick={onClick}>
			{/*<ImgByAnimation img={props.img} className="Woman__pic"/>*/}
			{isActive &&
				<div className="Woman__pic Woman__desc">
					{t("women.birthdate")}:&nbsp;{formattedBirthday}<br/>
					{t("women.age")}:&nbsp;{ages}<br/>
					{t("women.height")}:&nbsp;{props.height}<br/>
					{t("women.status")}:&nbsp;{props.status}<br/>
					Profession:&nbsp;{props.profession}<br/>
					{t("women.children")}:&nbsp;{props.children}<br/>
					{t("women.smoking")}:&nbsp;{props.smoking}<br/>
					{t("women.languages.title")}:&nbsp;{props.language}<br/>
				</div>
			}

			<Swiper
				spaceBetween={50}
				slidesPerView={1}
				pagination={true}
				modules={[Pagination]}
				ref={sliderRef}
			>
				{props.photos && props.photos.map(photo =>
					<SwiperSlide key={photo}>
						<div className="Woman__pic">
							<img src={photo} alt="pic" className="ImgByAnimation_img"/>
						</div>
					</SwiperSlide>
				)}
			</Swiper>
			{props.photos.length > 1 && <>
				<img src={prev} alt="previous" onClick={handlePrev} className="Woman__pic__nav Woman__pic__nav-prev"/>
				<img src={next} alt="next" onClick={handleNext} className="Woman__pic__nav Woman__pic__nav-next"/>
			</>}
			<p className="Woman__p">{props.name}</p>
		</div>
	);
};

export default Woman;