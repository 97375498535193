import React from 'react';
import './MyBtn2.css';

const MyBtn2 = (props) => {
  return (
    <div className={props.className}>
      <a className="MyBtn2" href={props.href}>
        {props.name}
      </a>
    </div>
  );
};

export default MyBtn2;