import React, {useState} from 'react';
import MyBtn1 from "../UI/MyBtn1/MyBtn1";
import './Request.css';
import '../../index.php';
import request from '../../index.php';
import IMask from "imask";
import { useTranslation } from "react-i18next";

const Request = (props) => {

  const [name, setName] = useState('');

  window.addEventListener("load", function() {
    const element = document.getElementById('tel');
    const maskOptions = {
      mask: "+{33} 0-00-00-00-00"
    }
    const mask = new IMask(element, maskOptions);


  })

  const checkName = () => {
    document.querySelector(".request__form__name").classList.add('wrong_input')
    if (name != 0) {
      document.querySelector(".request__form__name").classList.remove('wrong_input')
      document.querySelector('.request__form__tel').classList.add('wrong_input')
      let tel = document.querySelector('.request__form__tel').value;
      let telArr = tel.split('');
      if (telArr.length == 17) {
        document.querySelector('.request__form__btn').removeAttribute("disabled")
      }
    }
  }

  const checkTel = () => {
    document.querySelector('.request__form__tel').classList.add('wrong_input')
    let tel = document.querySelector('.request__form__tel').value;
    let telArr = tel.split('');
    if (telArr.length == 17) {
      document.querySelector('.request__form__tel').classList.remove('wrong_input')
      if (name != 0) {
        document.querySelector('.request__form__btn').removeAttribute("disabled")
      }
    }
  }

  const { t, i18n } = useTranslation();

  return (
    <section className={props.background} id={props.id}>
      <div className="container request">
        <h2 className="request__name">{t('request.title')}</h2>
        <form action={request} autoComplete={'off'} method={'post'} className="request__form">
          <input name="name" placeholder={t('request.placeholderName')} type="text" className="request__form__name"
                 onChange={e => {setName(e.target.value); checkName()}} onClick={checkName}/>
          <input id="tel" name="number" placeholder="+33 9-99-99-99-99" type="text" className="request__form__tel"
                 onMouseOut={checkTel}/>
          <button disabled type={'submit'} className={'request__form__btn'} >{t('request.apply')}</button>
        </form>
        <p className="request__desc">{t('request.text1.line')}&nbsp;<a href={'/policy'}>{t('request.text1.link')}</a>
        <br/><br/><br/>{t('request.text2.line1')}
          <br/>{t('request.text2.line2')}
          <br/>{t('request.text2.line3')}</p>
        <img src={props.img} alt="" className="request__img"/>
      </div>
    </section>
  );
};

export default Request;