import React from 'react';
import './ImgByAnimation.css';

const ImgByAnimation = (props) => {

  window.addEventListener("load", function() {
    document.querySelectorAll('.ImgByAnimation_img').forEach(img => {
      // Создать наблюдателя
      const observer = new IntersectionObserver(entries => {
        // перебор записей
        entries.forEach(entry => {
          // если элемент появился
          if (entry.isIntersecting) {
            // добавить ему CSS-класс
            entry.target.classList.add('img_animation');
          }
        });
      });
      // Сообщить наблюдателю, какие элементы следует отслеживать
      observer.observe(img);

    });
  })

  return (
    <div className={props.className}>
      <img src={props.img} alt="pic" className="ImgByAnimation_img"/>
    </div>
  );
};

export default ImgByAnimation;