import React from 'react';
import '../style/History.css';
import pic1 from '../img/history1.png';
import pic2 from '../img/history2.png';
import backl from '../img/left-back.svg';
import backr from '../img/right-back.svg';
import ImgByAnimation from "../components/UI/ImgByAnimation/ImgByAnimation";
import { useTranslation } from "react-i18next";

const History = () => {
	const { t, i18n } = useTranslation();

	return (<div className={'history_section'}>
		<div className="container history">
			<h2 className="history__name">{t('history.title')}</h2>
			{t('history.text1') &&
				<p className="history__desc">{t('history.text1.line1')}<br/>{t('history.text1.line2')}
					<br/>{t('history.text1.line3')}
					<br/>{t('history.text1.line4')}
					<br/>{t('history.text1.line5')}
				</p>}
			<h3 className="history__h">{t('history.whoWeAre')}</h3>
			<div className="history__block-1">
				<ImgByAnimation img={pic2} className="history__block-1__img__1000"/>
				<p className="history__block-1__txt-1">
					{t('history.whoWeAreText.line1') && <>{t('history.whoWeAreText.line1')} </>}
					<b>{t('history.whoWeAreText.lineBold')} </b>
					{t('history.whoWeAreText.line2')}
					{t('history.whoWeAreText.line3') && <>
						<br/>{t('history.whoWeAreText.line3')}
						<br/>{t('history.whoWeAreText.line4')}
						<br/>{t('history.whoWeAreText.line5')}
						<br/>{t('history.whoWeAreText.line6')}
						<br/>{t('history.whoWeAreText.line7')}
						<br/>{t('history.whoWeAreText.line8')}</>}
					<br/>
				</p>
				<ImgByAnimation img={pic1} className="history__block-1__img"/>
			</div>
			<div className="history__block-2">
				<ImgByAnimation img={pic2} className="history__block-2__img"/>
				<ImgByAnimation img={pic1} className="history__block-2__img__1000"/>
				<p className="history__block-2__txt-2">
					{t('history.text2.line1')}
					{t('history.text2.line2') && <>
						<br/>{t('history.text2.line2')}
						<br/>{t('history.text2.line3')}
						<br/>{t('history.text2.line4')}
						<br/>{t('history.text2.line5')}
						<br/>{t('history.text2.line6')}
						<br/>{t('history.text2.line7')}
						<br/>{t('history.text2.line8')}
						<br/>{t('history.text2.line9')}
						<br/>{t('history.text2.line10')}
						<br/>{t('history.text2.line11')}
					</>}
				</p>
			</div>
			{t('history.text3') && <p className="history__txt-3">
				{t('history.text3.line1')}
				<br/>{t('history.text3.line2')}
				<br/>{t('history.text3.line3')}
				<br/>{t('history.text3.line4')}
				<br/>{t('history.text3.line5')}
				<br/>{t('history.text3.line6')}
				<br/>{t('history.text3.line7')}
			</p>}
		</div>
		<img src={backl} alt="" className="history__backl"/>
		<img src={backr} alt="" className="history__backr"/>
	</div>);
};

export default History;