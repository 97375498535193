import React from 'react';
import inst from "../../img/inst.svg";
import fb from "../../img/facebook.svg";
import wapp from "../../img/wapp.svg";
import './Footer.css';
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={'footer_section'}>
      <div className="container footer">
        <div className="footer__block_1">
        <ul className="footer__menu">
          <li className="footer_list_el">
            <a href="/service" className="footer_list_el_a footer_list_el__services">{t('footer.service')}</a>
            <a href="/womans" className="footer_list_el_a">{t('footer.ourClients')}</a>
          </li>
          <li className="footer_list_el">
            <a href="/history" className="footer_list_el_a">{t('footer.ourHistory')}</a>
            <a href="/contacts" className="footer_list_el_a">{t('footer.contacts')}</a>
          </li>
        </ul>
        <div className="footer__contacts">
          <a href="tel:+33665169078" className="footer__contacts__tel">+33 6 65 16 90 78</a>
          <a href="mailto:agence.lasf@gmail.com" className="footer__contacts__mail">agence.lasf@gmail.com</a>
          <div className="footer__contacts__icons">
            <a href="https://instagram.com/lasf.agence" className="footer__contacts__icons__inst" target="_blank">
              <img src={inst} alt="instagram" className="footer__contacts__icons__inst__img"/>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100093200285349" className="footer__contacts__icons__facebook" target="_blank">
              <img src={fb} alt="facebook" className="footer__contacts__icons__facebook__img"/>
            </a>
            <a href="https://wa.me/+33665169078" className="footer__contacts__icons__wapp" target="_blank">
              <img src={wapp} alt="what's app" className="footer__contacts__icons__wapp__img"/>
            </a>
          </div>
        </div>
        </div>
        <div className="footer__dev">
          <p className="footer__dev__p">{t('footer.designer')} <a href="https://t.me/after_bv" className="footer__dev__p__a" target="_blank">Bozhenova Vasilisa</a></p>
          <p className="footer__dev__p">{t('footer.developer')} <a href="https://t.me/milly_bo" className="footer__dev__p__a" target="_blank">Bozhenova Miloslava</a></p>
        </div>
      </div>
    </div>
  );
};

export default Footer;